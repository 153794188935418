import React from 'react'
import Navbar from '../components/Header/Navbar'
import Home from '../components/Home/Home'
import About from '../components/About/About'
import RecentWork from '../components/RecentWork/RecentWork'
import Services from '../components/Services/Services'
import FAQs from '../components/FAQs/FAQs'
import Companies from '../components/Companies/Companies'
import Benefits from '../components/Benefits/Benefits'
import Footer from '../components/Footer/Footer'
import Testimonial from '../components/Testimonial/Testimonial'
import { Helmet } from 'react-helmet'

const Portfolio = () => {
    return (
        <>
            <Helmet>
                <title>
                    Designerom - Figma Expert & UI/UX Designer Extraordinaire!
                </title>
                <meta
                    name="description"
                    content="Figma Expert & UI/UX Designer Extraordinaire!"
                />
                <noscript>Figma Expert & UI/UX Designer Extraordinaire!</noscript>
            </Helmet>

            <div>
                <Navbar />
                <Home />
                <Companies />
                <About />
                <Benefits />
                <RecentWork />
                <Services />
                <Testimonial />
                <FAQs />
                <Footer />
            </div>

        </>
    )
}

export default Portfolio